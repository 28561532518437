<template>
<q-form ref="editForm">
  <c-card title="LBLDETAIL" class="cardClassDetailForm">
    <template slot="card-button">
      <q-btn-group outline >
        <c-update-btn 
          name="updateBtn"
          :data="param.updateBtnData"
          :btnEditable="btnEditable"
          :flagCondition="flagCondition"
          @back="back"
        />
        <c-btn 
          v-show="editable&&!deleteDisabled&&isOld" 
          label="LBLREMOVE" 
          icon="remove" 
          @btnClicked="removePlan" />
        <c-btn 
          v-show="editable && !disabled" 
          :isSubmit="isSave"
          :url="saveUrl"
          :param="issueCause"
          :mappingType="mappingType"
          label="LBLSAVE" 
          icon="save"
          @beforeAction="savePlan"
          @btnCallback="saveCallback" />
        <c-btn 
          v-show="editable&&!deleteDisabled&&isOld"
          :isSubmit="isComplete"
          :url="completeUrl"
          :param="issueCause"
          mappingType="PUT"
          label="LBLCOMPLETE" 
          icon="check"
          @beforeAction="completePlan"
          @btnCallback="completePlanCallback" />
      </q-btn-group>
    </template>
    <template slot="card-detail">
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <c-text
          :required="true" 
          :editable="editable"
          :disabled="disabled"
          label="제목"
          name="issuesTitle"
          v-model="issueCause.issuesTitle">
        </c-text>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
        <c-datepicker
          :required="true" 
          :editable="editable"
          :disabled="disabled"
          default="today"
          type="year"
          label="대상년도"
          name="issuesYear"
          v-model="issueCause.issuesYear"
        />
      </div>
      <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
        <c-select
          :required="true" 
          :editable="editable"
          :disabled="disabled||isOld"
          codeGroupCd="ISSUES_ITEM_CLASS_CD"
          type="edit"
          itemText="codeName"
          itemValue="code"
          name="issuesClassCd"
          label="구분"
          v-model="issueCause.issuesClassCd"
          @datachange="targetDataChange"
        ></c-select>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
        <c-plant 
          :required="true" 
          :disabled="disabled||isOld"
          :editable="editable" 
          type="edit" 
          name="plantCd" 
          v-model="issueCause.plantCd" />
      </div>
      <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
        <c-dept 
          :editable="editable"
          :disabled="disabled||isOld"
          :required="true" 
          type="dept_user" 
          label="작성부서" 
          name="issuesDeptCd" 
          v-model="issueCause.issuesDeptCd" 
          @datachange="targetDataChange"/>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
        <c-field 
          :editable="editable"
          :disabled="disabled"
          :required="true" 
          label="작성자" 
          name="issuesUserId" 
          v-model="issueCause.issuesUserId" />
      </div>
      <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
        <c-datepicker
          :required="true" 
          :editable="editable"
          :disabled="disabled"
          label="작성일"
          name="issuesDate"
          v-model="issueCause.issuesDate"
        />
      </div>
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <c-table
          ref="table"
          title="평가대상 목록"
          :columns="grid.columns"
          :gridHeight="grid.height"
          :data="issueCause.resultList"
          :filtering="false"
          :columnSetting="false"
          :usePaging="false"
          :editable="editable&&!disabled"
          selection="multiple"
          rowKey="issuesResultId"
        > 
          
          <template v-slot:customArea="{ props, col }">
            <template v-if="col.name === 'issuesTypeName'">
              {{props.row.issuesTypeName}}
              <div v-if="editable">
                <q-btn-group outline class="ColumInnerBtnGroup">
                  <q-btn
                    icon="add"
                    color="red-6"
                    text-color="white"
                    class="ColumInnerBtn"
                    align="center"
                    @click.prevent="innerBtnClicked(col, props)">
                    <q-tooltip>
                      {{'평가대상구분별 평가대상 추가'}}
                    </q-tooltip>
                  </q-btn>
                </q-btn-group>
              </div>
            </template>
          </template>
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn 
                v-if="editable&&!disabled" 
                :showLoading="false" 
                label="법령 추가" 
                icon="add" 
                @btnClicked="addLaw" />
              <c-btn 
                v-if="editable&&!disabled" 
                :showLoading="false" 
                label="평가대상 추가" 
                icon="add" 
                @btnClicked="addTarget" />
              <c-btn 
                v-if="editable&&!disabled" 
                label="제외" 
                icon="remove" 
                @btnClicked="deleteTarget" />
            </q-btn-group>
          </template>
          
          <template slot="suffixTitle">
            <template>
              <font class="text-negative" style="font-size:0.8em;font-weight:300;">
                ※ 펑가대상구분 중 준수의무는 부서별로 법령을 자동 조회합니다. (단 구분,사업장,작성부서를 필수로 입력하면 조회됩니다.)
              </font>
            </template>
          </template>
        </c-table>
      </div>
    </template>
  </c-card>
  <c-dialog :param="popupOptions"></c-dialog>
</q-form>
</template>

<script>
import { uid } from 'quasar'
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'issueCause',
  props: {
    param: {
      type: Object,
      default: () => ({
        issuesId: '',
        issuesStepCd: '',
        updateBtnData: {
          title: '',
          flag: false,
          research: '',
        },
      }),
    },
    height: {
      type: String,
      default: function() {
        return '';
      },
    },
  },
  data() {
    return {
      splitter: 5,
      issueCause: {
        issuesId: '',
        issuesTitle: '',
        issuesClassCd: null,
        issuesDeptCd: '',
        issuesUserId: '',
        issuesDate: '',
        issuesYear: '',
        plantCd: '',
        issuesStepCd: '',
        resultList: [],
        deleteResultList: [],
      },
      grid: {
        columns: [
          {
            name: 'issuesTypeName',
            field: 'issuesTypeName',
            label: '평가대상구분',
            align: 'center',
            type: 'custom',
            style: 'width:15%',
            sortable: false,
          },
          {
            required: true,
            name: 'issuesName',
            field: 'issuesName',
            label: '평가대상명',
            align: 'left',
            type: 'text',
            style: 'width:15%',
            sortable: false,
          },
          {
            required: true,
            name: 'issuesContents',
            field: 'issuesContents',
            label: '이슈사항',
            align: 'left',
            type: 'textarea',
            style: 'width:70%',
            sortable: false,
          },
        ],
      },
      editable: true,
      isSave: false,
      mappingType: 'POST',
      saveUrl: '',
      detailUrl: '',
      completeUrl: '',
      deleteUrl: '',
      targetListUrl: '',
      isComplete: false,
      popupOptions: {
        isApproval: true,
        target: null,
        title: '',
        visible: false,
        param: {},
        closeCallback: null,
      },
    };
  },
  computed: {
    isOld() {
      return Boolean(this.issueCause.issuesId)
    },
    deleteDisabled() {
      return this.issueCause.issuesStepCd !== 'IIS0000001'
    },
    disabled() {
      return Boolean(this.issueCause.issuesStepCd) && this.issueCause.issuesStepCd !== 'IIS0000001' && !this.param.updateBtnData.flag
    },
    btnEditable() {
      return this.editable && (this.issueCause.issuesStepCd !== 'IIS0000001' && Boolean(!this.issueCause.sysApprovalRequestId)) && Boolean(this.issueCause.issuesId) 
    },
    flagCondition() {
      return this.issueCause.issuesStepCd === 'IIS0000001'
    },
  },
  watch: {
    'param.updateBtnData.research'() {
      this.getDetail();
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.sai.iei.issue.get.url;
      this.completeUrl = transactionConfig.sai.iei.issue.update.url
      this.saveUrl = transactionConfig.sai.iei.issue.insert.url
      this.deleteUrl = transactionConfig.sai.iei.issue.delete.url
      this.targetListUrl = selectConfig.sai.iei.item.targetList.url;
      // code setting
      // list setting
      this.getDetail();
    },
    getDetail() {
      if (this.param.issuesId) {
        this.$http.url = this.$format(this.detailUrl, this.param.issuesId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.issueCause = _result.data;
        },);
      } else {
        this.issueCause.issuesYear = this.$comm.getThisYear()
        this.issueCause.issuesUserId = this.$store.getters.user.userId
        this.issueCause.issuesDeptCd = this.$store.getters.user.deptCd
        this.issueCause.regUserId = this.$store.getters.user.userId
        this.issueCause.regUserName = this.$store.getters.user.userName
        this.issueCause.issuesDate = this.$comm.getToday();
      }
    },
    savePlan() {
      if (this.param.issuesId) {
        this.saveUrl = transactionConfig.sai.iei.issue.update.url
        this.mappingType = 'PUT';
      } else {
        this.saveUrl = transactionConfig.sai.iei.issue.insert.url
        this.mappingType = 'POST';
        this.issueCause.issuesStepCd = 'IIS0000001'
      }
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          if (this.$comm.validTable(this.grid.columns, this.issueCause.resultList)) {
            window.getApp.$emit('CONFIRM', {
              title: '확인',
              message: '저장하시겠습니까?', 
              // TODO : 필요시 추가하세요.
              type: 'info', // success / info / warning / error
              // 확인 callback 함수
              confirmCallback: () => {
                this.issueCause.regUserId = this.$store.getters.user.userId
                this.issueCause.chgUserId = this.$store.getters.user.userId
                this.isSave = !this.isSave
              },
              // 취소 callback 함수
              cancelCallback: () => {
              },
            });
          }
        }
      })
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.$set(this.param, 'issuesId', result.data)
      this.$emit('emitStep', {
        name: 'keySetting',
        param: result.data
      })
      this.getDetail();
    },
    completePlan() {
      if (this.$comm.validTable(this.grid.columns, this.issueCause.resultList)) {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: 'MSGCOMPLETE', // 완료하시겠습니까?, // 완료하시겠습니까?
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.issueCause.chgUserId = this.$store.getters.user.userId
            this.issueCause.issuesStepCd = 'IIS0000005'

            this.isComplete = !this.isComplete
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    completePlanCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      // 계획 상세 조회
      this.getDetail();
      // stepper 이동
      this.$emit('emitStep', {
        name: 'stepBystep',
        param: this.issueCause.issuesStepCd
      })
    },
    removePlan() {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',
        message: 'MSGREMOVE', // 삭제하시겠습니까?,
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.issueCause.issuesId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('emitStep', {
              name: 'closePopup'
            })
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    addLaw() {
      this.popupOptions.title = '법령 검색'; 
      this.popupOptions.param = {
        type: 'multiple',
        targetDeptCd: this.issueCause.issuesDeptCd
      };
      this.popupOptions.target = () => import(`${'@/pages/sai/law/register/lawRegisterPop.vue'}`);
      this.popupOptions.width = '80%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeLawPopup;
    },
    closeLawPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEachRight(data, item => {
          this.issueCause.resultList.splice(0, 0, {
            issuesResultId: uid(),
            issuesId: this.param.issuesId,
            issuesTypeCd: 'IIT0000020',
            issuesTypeName: '준수업무',
            issuesName: item.lawName + '/' + item.relevantClause,
            issuesContents: '',
            issuesCause: '',
            issuesRisk: '',
            currentManagementMeasures: '',
            possibility: 0,
            severity: 0,
            risks: 0,
            actionPlan: '',
            actionCheckFlag: 'N',
            sortOrder: item.sortOrder,
            measurementEffectivenessAssessment: '',
            editFlag: 'C', regUserId: this.$store.getters.user.userId,
          })
        })
      }
    },
    addTarget() {
      this.popupOptions.title = '평가대상 검색'; // 평가대상 검색
      this.popupOptions.param = {
        type: 'multiple',
        plantCd: this.$store.getters.user.plantCd,
        issuesClassCd: this.issueCause.issuesClassCd
      };
      this.popupOptions.target = () => import(`${'./internalExternalIssuesItemPop.vue'}`);
      this.popupOptions.width = '60%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeTargetPopup;
    },
    closeTargetPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(this.issueCause.resultList, _item => {
            return item.issuesTypeName === _item.issuesTypeName && item.issuesName === _item.issuesName
          }) === -1) {
            this.issueCause.resultList.splice(0, 0, {
              issuesResultId: uid(),
              issuesId: this.param.issuesId,
              issuesTypeCd: item.issuesTypeCd,
              issuesTypeName: item.issuesTypeName,
              issuesName: item.issuesName,
              issuesContents: '',
              issuesCause: '',
              issuesRisk: '',
              currentManagementMeasures: '',
              possibility: 0,
              severity: 0,
              risks: 0,
              actionPlan: '',
              actionCheckFlag: 'N',
              sortOrder: item.sortOrder,
              measurementEffectivenessAssessment: '',
              editFlag: 'C', regUserId: this.$store.getters.user.userId,
            })
          }
        })
      }
    },
    deleteTarget() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$_.forEach(selectData, (item) => {
          if (!this.issueCause.deleteResultList) this.issueCause.deleteResultList = [];
          if (this.$_.findIndex(this.issueCause.deleteResultList, {
            issuesResultId: item.issuesResultId,
              }) === -1 
            && item.editFlag !== "C"
          ) {
            this.issueCause.deleteResultList.push(item);
          }
          this.issueCause.resultList = this.$_.reject(this.issueCause.resultList, item);
        });
      }
    },
    targetDataChange() {
      if (!this.issueCause.issuesClassCd || !this.issueCause.issuesDeptCd) return;
      this.$http.url = this.targetListUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        plantCd: this.issueCause.plantCd,
        issuesClassCd: this.issueCause.issuesClassCd,
        issuesDeptCd: this.issueCause.issuesDeptCd,
        useFlag: 'Y',
      };
      this.$http.request((_result) => {
        let results = [];
        this.$_.forEach(_result.data, _item => {
          results.push({  
            issuesResultId: uid(),
            issuesId: this.param.issuesId,
            issuesTypeCd: _item.issuesTypeCd,
            issuesTypeName: _item.issuesTypeName,
            issuesName: _item.issuesName,
            issuesCause: '',
            issuesContents: '',
            issuesRisk: '',
            currentManagementMeasures: '',
            possibility: 0,
            severity: 0,
            risks: 0,
            actionPlan: '',
            measurementEffectivenessAssessment: '',
            editFlag: 'C', 
            actionCheckFlag: 'N',
            sortOrder: _item.sortOrder,
            regUserId: this.$store.getters.user.userId,
          }) 
        })
        this.issueCause.resultList = results;
      },);
    },
    innerBtnClicked(col, props) {
      this.issueCause.resultList.splice(props.rowIndex, 0, {
        issuesResultId: uid(),
        issuesId: this.param.issuesId,
        issuesTypeCd: props.row.issuesTypeCd,
        issuesTypeName: props.row.issuesTypeName,
        issuesName: '',
        issuesCause: '',
        issuesContents: '',
        issuesRisk: '',
        currentManagementMeasures: '',
        possibility: 0,
        severity: 0,
        risks: 0,
        actionPlan: '',
        measurementEffectivenessAssessment: '',
        editFlag: 'C', 
        actionCheckFlag: 'N',
        sortOrder: props.row.sortOrder + 1,
        regUserId: this.$store.getters.user.userId,
      })
    },
    /**
     * 재조회
     * 목적 : 돌아가기전 데이터를 저장 했을 수도 있지만 
     *        입력만 하고 돌아가는 경우를 대비하기 위함
     */
    back() {
      this.getDetail();
    },
  }
};
</script>
